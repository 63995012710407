import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PageHero = ({ image, title }) => (
  <div className="relative bg-cream">
    <GatsbyImage
      className={`max-h-96 object-cover object-top`}
      image={getImage(image)}
      alt={title}
      loading="eager"
    />
    <div className="contour-overlay flex items-end  py-4 md:py-12 md:inset-0 md:absolute">
      <div className="container">
        <h1 className="mb-0 md:text-white section-title-mobile">{title}</h1>
      </div>
    </div>
  </div>
)

export default PageHero
